import jQuery from "../../_snowpack/pkg/jquery.js";
import Barba from "../../_snowpack/pkg/@barba/core.js";
import "../../_snowpack/pkg/multiple-select/dist/multiple-select.css";
import "./plugins/select2.css";
import Preloader from "./components/preloader/Preloader.js";
import Sidemenu from "./components/Sidemenu/Sidemenu.js";
import Header from "./components/Header/Header.js";
import Main from "../../controller/Main.js";
import axios from "../../_snowpack/pkg/axios.js";
import "./components/table/table.css";
import "./components/table/Pagination.css";
import "./components/preloader/Preloader.css";
import "./components/Toolbar/Toolbar.css";
import "./components/Alert/Alert.css";
import "./components/Button/Button.css";
import "./components/Shimmer/Shimmer.css";
import "./components/Sidebar/Sidebar.css";
import "./components/Toaster/Toaster.css";
import "./components/Header/Header.css";
import "./App.css";
import "./admin-style.css";
import { setUser } from "./functions/Auth/getUser.js";
(function () {
  window.onload = function () {
    window.$ = window.jQuery = jQuery;
    import("https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.13/js/select2.min.js");
    import("../../_snowpack/pkg/multiple-select.js");
    // import('multiple-select/dist/mult')
    // app basics
    window.Barba = Barba;
    window.header = new Header();
    window.header.show();
    var loader = new Preloader();
    window.app_loader = loader;
    window.sidemenu = new Sidemenu();
    window.sidemenu.init({
      prevent: "login"
    });
    Main();

    // if ('serviceWorker' in navigator) {
    //   window.addEventListener('load', function () {
    //     navigator.serviceWorker.register('service-worker.js').then(function (registration) {
    //       console.log('ServiceWorker registration successful with scope: ', registration.scope);
    //     }, function (err) {
    //       console.log('ServiceWorker registration failed: ', err);
    //     });
    //   });
    // }

    (function () {
      $("#login_form").submit(function (e) {
        e.preventDefault();
        var fd = new FormData();
        fd.append("username", $("#username").val());
        fd.append("password", $("#password").val());

        // login action
        axios.post("https://travinno.nashimnazar.in/action/loginAction.php", fd).then(function (response) {
          if (response.data.success) {
            // Store the token in localStorage or sessionStorage
            localStorage.setItem("token", response.data.token);
            var userObj = {
              role: response.data.role,
              name: response.data.name,
              department: response.data.department
            };
            setUser(userObj);
            switch (userObj.department) {
              case "contract":
                window.location = "./view/contract/index.html";
                break;
              case "sales":
                window.location = "./view/sales/index.html";
                break;
              case "quotation":
                window.location = "./view/quotation/listQuotation.html";
                break;
              case "admin":
                window.location = "./view/admin/index.html";
                break;
            }
          } else {
            alert(response.data.msg);
          }
        });
      });
    })();

    // $("body").fadeIn(1000);
    // show frame
  };
})();